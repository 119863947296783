import $ from 'jquery';
import { CLASSES, BREAKPOINTS } from '@/scripts/helpers/constants';

const DOCUMENT_SCROLL_DURATION = 800;
const DOCUMENT_SCROLL_STYLE = 'swing';

const scrollToTop = () => {
  $('html, body').animate(
    {
      scrollTop: 0,
    },
    DOCUMENT_SCROLL_DURATION,
    DOCUMENT_SCROLL_STYLE,
  );
};

$(document).ready(() => {
  const { IS_VISIBLE } = CLASSES;
  const { TABLET, DESKTOP_LG } = BREAKPOINTS;
  const button = $('.button-scroll-up');
  const bottomOffset = button.outerHeight(true);
  let lastScrollTop = 0;

  button.on('click keyup', (e) => {
    e.preventDefault();
    scrollToTop();
  });

  $(window).scroll(function () {
    const currentScrollTop = $(this).scrollTop();

    if (currentScrollTop <= lastScrollTop && currentScrollTop >= 200) {
      button.addClass(IS_VISIBLE);
    } else {
      button.removeClass(IS_VISIBLE);
    }

    lastScrollTop = currentScrollTop;

    if ($(window).scrollTop() + $(window).height() >= $(document).height() - bottomOffset) {
      button.addClass(IS_VISIBLE);
    }
  });
});
